import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import MessageInput from "./components/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { getToken } from "./authenticator";
import {
  ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "./appInsights";
import axios from "axios";
import { createBrowserHistory } from "history";
import { ApplicationInsights, ApplicationInsightsContainer } from "@microsoft/applicationinsights-web";

import {
  appendBotMessage,
  getConversations,
  getConnectionString,
  createNewConversation,
  getModels,
  setCurrentBotReply,
  addStreamingMessageToConversation,
  cancelBotMessage,
} from "./store/messageSlice";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  LanguageIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

function App({ provider }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [responseText, setResponseText] = useState("");
  const abortControllerRef = useRef(null); // Use ref to store AbortController
  const reactPlugin = new ReactPlugin();

  const dispatch = useDispatch();
  const {
    conversations,
    messages,
    loading,
    loadingMessages,
    currentConversation,
    selectedModel,
  } = useSelector((state) => state.messages);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const addNewChat = async (provider) => {
    const token = await getToken(provider);
    dispatch(createNewConversation(token));
  };

  useEffect(() => {
    const getConnectionString = async (accessToken) => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/appinsights",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching connection string:", error);
        throw error;
      }
    };

    const activateAppInsights = async () => {
      const token = await getToken(provider);
      const response = await getConnectionString(token);

      const browserHistory = createBrowserHistory({ basename: "" });
      
      var appInsights = new ApplicationInsights({
        config: {
          connectionString: response.connectionstring,
          // *** If you're adding the Click Analytics plug-in, delete the next line. ***
          extensions: [reactPlugin],
          // *** Add the Click Analytics plug-in. ***
          // extensions: [reactPlugin, clickPluginInstance],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
            // *** Add the Click Analytics plug-in. ***
            // [clickPluginInstance.identifier]: clickPluginConfig
          },
        },
      });

      appInsights.loadAppInsights();
      console.log("AppInsights activated");
    }

    activateAppInsights();

    const fetchTokenAndMessages = async () => {
      try {
        const token = await getToken(provider);
        dispatch(getConversations(token));
        dispatch(getModels(token));
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };
    fetchTokenAndMessages();
  }, [dispatch, provider]);

  // Stop the event stream
  const stopStream = (provider) => {
    dispatch(cancelBotMessage());
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      console.log("Stream aborted by user");
      abortControllerRef.current = null; // Reset abortController after aborting
    }
    getToken(provider).then((token) => {
      const accessToken = token;
      dispatch(
        addStreamingMessageToConversation({
          accessToken,
          conversationId: currentConversation,
          message: responseText,
          model: selectedModel,
        })
      );
    });
  };

  const handleSubmit = async (messageText) => {
    if (messageText.trim() === "" || !currentConversation) return;

    try {
      const token = await getToken(provider);
      const accessToken = token;
      const conversationId = currentConversation;
      let responseForRequest = "";
      const url =
        process.env.REACT_APP_BACKEND_URL + `/messageStream/${conversationId}`;

      // Store new AbortController in ref
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      setResponseText("");
      dispatch(setCurrentBotReply(""));

      await fetchEventSource(url, {
        method: "POST",
        body: JSON.stringify({
          message: messageText,
          model: selectedModel,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        signal,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection established");
          } else {
            console.error("Connection failed");
          }
          dispatch(
            appendBotMessage({
              PartitionKey: "assistant added",
              RowKey: "assistant added",
              message: "",
              party: "AssistentFrontend",
            })
          );
        },
        onmessage(event) {
          try {
            const parsedData = JSON.parse(event.data);
            setResponseText(
              (prevText) => prevText + decodeURIComponent(parsedData.chunk)
            );
            responseForRequest += decodeURIComponent(parsedData.chunk);
          } catch (err) {
            console.error("Error parsing message data", err);
          }
        },
        onclose() {
          console.log("Connection closed");
          getToken(provider).then((token) => {
            const accessToken = token;
            dispatch(
              addStreamingMessageToConversation({
                accessToken,
                conversationId: currentConversation,
                message: responseForRequest,
                model: selectedModel,
              })
            );
          });
        },
        onerror(err) {
          console.error("Stream error", err);
        },
      });
    } catch (error) {
      console.error("Error submitting message:", error);
    }
  };

  useEffect(() => {
    if (responseText) {
      dispatch(setCurrentBotReply(responseText));
    }
  }, [responseText, dispatch]);

  return (
    <reactPlugin>
      <div className="relative flex h-screen">
        {/* Sidebar */}
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          provider={provider}
        />

        {/* Main content area */}
        <div
          className={`flex flex-col w-full transition-all duration-300 flex h-screen ${
            isSidebarOpen ? "ml-0  xl:ml-64" : "ml-0 lg:ml-16"
          }`}
        >
          {/* Header */}
          <Header toggleSidebar={toggleSidebar} />

          {/* Show the three cards by default (if no currentConversation) */}
          {!currentConversation ? (
            <div className="flex-1 flex flex-col items-center justify-center bg-gray-100 p-8">
              <h1 className="text-2xl font-semibold text-gray-700 mb-6">
                Welcome! What would you like to do?
              </h1>

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full max-w-4xl">
                {/* Card 1: Add New Chat */}
                <div
                  onClick={() => addNewChat(provider)}
                  className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center cursor-pointer hover:shadow-lg transition-shadow"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="w-10 h-10 text-blue-500 mb-4" />
                  <h2 className="text-xl font-semibold text-gray-700 mb-2">
                    New Chat
                  </h2>
                  <p className="text-gray-500">
                    Start a conversation with JENSEN AI.
                  </p>
                </div>

                {/* Card 2: Go to JENSEN Translator */}
                <a
                  href="https://translator.jensen-group.com/simple_term_translation/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center cursor-pointer hover:shadow-lg transition-shadow"
                >
                  <LanguageIcon className="w-10 h-10 text-green-500 mb-4" />
                  <h2 className="text-xl font-semibold text-gray-700 mb-2">
                    Go to JENSEN Translator
                  </h2>
                  <p className="text-gray-500">
                    Translate texts with JENSEN Translator.
                  </p>
                </a>

                {/* Card 3: What's new? */}
                <a
                  href="#"
                  //className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center cursor-pointer hover:shadow-lg transition-shadow"
                  className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center text-center cursor-not-allowed opacity-50 hover:shadow-lg transition-shadow"
                >
                  <SparklesIcon className="w-10 h-10 text-yellow-500 mb-4" />
                  <h2 className="text-xl font-semibold text-gray-700 mb-2">
                    What's next?
                  </h2>
                  <p className="text-gray-500">
                    Check out upcoming features and updates.
                  </p>
                </a>
              </div>
            </div>
          ) : (
            <>
              {/* Main content area that fills the remaining space below the header */}
              <main className="flex-1 overflow-y-auto bg-gray-100 relative">
                <MainContent provider={provider} />
              </main>
              <div className="sticky bottom-0 bg-gray-100 pb-4 relative px-2">
                <MessageInput
                  handleSubmit={handleSubmit}
                  stopStream={stopStream} // Pass stopStream function
                  provider={provider}
                />
              </div>
            </>
          )}

          {/* Loading spinner over both MainContent and MessageInput if loadingMessages is true */}
          {!loading && loadingMessages && (
            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-40">
              <div className="w-16 h-16 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
            </div>
          )}
        </div>

        {/* Global Loading Spinner covering the whole app */}
        {loading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="w-16 h-16 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </reactPlugin>
  );
}

export default App;
