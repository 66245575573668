import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch messages, passing the access token in the Authorization header
export const getConversations = createAsyncThunk(
  "messages/fetchMessagesWithAuth",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/conversation/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while fetching messages");
      }
    }
  }
);

export const getModels = createAsyncThunk(
  "messages/getModels",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/model", {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while fetching messages");
      }
    }
  }
);

export const getConnectionString = createAsyncThunk(
  "utils/getConnectionstring",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/appinsights",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while fetching messages");
      }
    }
  }
);

export const createNewConversation = createAsyncThunk(
  "messages/createNewConversation",
  async (accessToken, { rejectWithValue }) => {
    try {
      // Log the body content before sending
      const requestBody = { name: "New Chat" };
      console.log("Request body:", requestBody);

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/conversation/",
        requestBody, // The data being sent in the body
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Log response
      console.log("Response data:", response.data);

      return response.data; // Return the response data directly
    } catch (error) {
      // Log errors for better debugging
      console.error("Error creating conversation:", error);

      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue(
          "No response from server while creating conversation"
        );
      } else {
        return rejectWithValue(
          "Error occurred while creating a new conversation"
        );
      }
    }
  }
);

// Async thunk to delete a conversation by ID
export const deleteConversation = createAsyncThunk(
  "messages/deleteConversation",
  async ({ token, conversationId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `/conversation/${conversationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { conversationId, data: response.data }; // Return the conversation ID to remove it from the store
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue(
          "No response from server while deleting conversation"
        );
      } else {
        return rejectWithValue("Error occurred while deleting conversation");
      }
    }
  }
);

export const getMessagesForConversation = createAsyncThunk(
  "messages/getMessagesForConversation",
  async ({ accessToken, conversationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/message/${conversationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
          },
        }
      );
      return response.data; // Return the messages from the response
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while fetching messages");
      }
    }
  }
);

export const addMessageToConversation = createAsyncThunk(
  "messages/addMessageToConversation",
  async (
    { accessToken, conversationId, message, model },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/message/${conversationId}`,
        { message: message, model: model }, // Message body
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      return response.data; // Return the created message
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while sending message");
      }
    }
  }
);

export const addStreamingMessageToConversation = createAsyncThunk(
  "messages/addStreamingMessageToConversation",
  async (
    { accessToken, conversationId, message, model },
    { rejectWithValue }
  ) => {
    try {
      console.log("Sending message to conversation", message);
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/messageLog/${conversationId}`,
        { message: message, model: model }, // Message body
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Pass the access token with Bearer prefix
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      return response.data; // Return the created message
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        return rejectWithValue("No response from server");
      } else {
        return rejectWithValue("Error occurred while sending message");
      }
    }
  }
);

const initialState = {
  conversations: [],
  messages: [], // Store messages for the current conversation
  currentConversation: null,
  loadingMessages: false, // Loading state for fetching messages
  error: null,
  creatingConversation: false,
  deletingConversation: false,
  sendingMessage: false, // Loading state for sending a message
  models: [],
  selectedModel: null,
  currentBotReply: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setCurrentConversation(state, action) {
      state.currentConversation = action.payload;
      state.messages = []; // Clear messages when conversation changes
    },
    appendBotMessage(state, action) {
      state.messages.push(action.payload); // Append the bot message to the messages array
      state.sendingMessage = true;
    },
    cancelBotMessage(state, action) {
      // Append the bot message to the messages array
      state.sendingMessage = false;
    },
    setCurrentBotReply(state, action) {
      state.currentBotReply = action.payload;
      if (
        state.messages.length > 0 &&
        state.messages[state.messages.length - 1].party === "AssistentFrontend"
      ) {
        state.messages[state.messages.length - 1].message = action.payload;
      }
    },
    appendMessageFromUser(state, action) {
      state.sendingMessage = true;
      state.messages.push(action.payload);
    },
    setSelectedModel(state, action) {
      state.selectedModel = action.payload;
    },
    renameConversation(state, action) {
      // Find the conversation by ID and update its name
      const { conversationId, newName } = action.payload;
      const conversation = state.conversations.find(
        (conv) => conv.conversation_id === conversationId
      );
      if (conversation) {
        // Shorten the name to fit the UI, e.g., 20 characters max
        conversation.name =
          newName.length > 20 ? `${newName.substring(0, 20)}...` : newName; // Update the conversation's name
      }
    },
  },
  extraReducers: (builder) => {
    // Fetch messages with auth
    builder
      .addCase(getConversations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.conversations = action.payload.conversations;
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });

    // Fetch models
    builder
      .addCase(getModels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.loading = false;
        state.models = action.payload.models;
        state.selectedModel = action.payload.models[0]; // Set the first model as the default
      })
      .addCase(getModels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });

    // Create new conversation
    builder
      .addCase(createNewConversation.pending, (state) => {
        state.creatingConversation = true;
        state.error = null;
      })
      .addCase(createNewConversation.fulfilled, (state, action) => {
        state.creatingConversation = false;
        state.conversations = action.payload.conversations; // Add the new conversation to the list
        if (action.payload.conversations.length > 0) {
          state.currentConversation =
            action.payload.conversations[0].conversation_id; // Set the newly created conversation as the current one
        }
      })
      .addCase(createNewConversation.rejected, (state, action) => {
        state.creatingConversation = false;
        state.error = action.payload || "Failed to create conversation";
      });

    // Delete conversation
    builder
      .addCase(deleteConversation.pending, (state) => {
        state.deletingConversation = true;
        state.error = null;
      })
      .addCase(deleteConversation.fulfilled, (state, action) => {
        state.deletingConversation = false;
        state.conversations = state.conversations.filter(
          (conversation) =>
            conversation.conversation_id !== action.payload.conversationId
        );

        if (
          (state.currentConversation =
            state.conversations.filter(
              (conversation) =>
                conversation.conversation_id !== action.payload.conversationId
            ) > 0)
        ) {
          state.currentConversation = state.conversations.filter(
            (conversation) =>
              conversation.conversation_id !== action.payload.conversationId
          )[0].conversation_id;
        }
      })
      .addCase(deleteConversation.rejected, (state, action) => {
        state.deletingConversation = false;
        state.error = action.payload || "Failed to delete conversation";
      });

    // Add message to conversation
    builder
      .addCase(addMessageToConversation.pending, (state) => {
        state.sendingMessage = true;
        state.error = null;
      })
      .addCase(addMessageToConversation.fulfilled, (state, action) => {
        state.sendingMessage = false;
        state.messages.push(action.payload); // Append the new message to the messages array

        // Rename conversation based on the new message's name property, if it exists
        const newMessage = action.payload;
        if (newMessage && newMessage.name) {
          state.conversations = state.conversations.map((conv) =>
            conv.conversation_id === action.meta.arg.conversationId
              ? {
                  ...conv,
                  name:
                    newMessage.name.length > 20
                      ? `${newMessage.name.substring(0, 20)}...`
                      : newMessage.name,
                }
              : conv
          );
        }
      })
      .addCase(addMessageToConversation.rejected, (state, action) => {
        state.sendingMessage = false;
        state.error = action.payload || "Failed to send message";
      });
    // Add message to conversation
    builder
      .addCase(addStreamingMessageToConversation.pending, (state) => {
        state.sendingMessage = false;
        state.error = null;
      })
      .addCase(addStreamingMessageToConversation.fulfilled, (state, action) => {
        state.sendingMessage = false;
        // Append the new message to the messages array

        // Rename conversation based on the new message's name property, if it exists
        const newMessage = action.payload;
        if (newMessage && newMessage.name) {
          state.conversations = state.conversations.map((conv) =>
            conv.conversation_id === action.meta.arg.conversationId
              ? {
                  ...conv,
                  name: newMessage.name,
                }
              : conv
          );
        }
      })
      .addCase(addStreamingMessageToConversation.rejected, (state, action) => {
        state.sendingMessage = false;
        state.error = action.payload || "Failed to send message";
      });

    // Fetch messages for a specific conversation
    builder
      .addCase(getMessagesForConversation.pending, (state) => {
        state.loadingMessages = true;
        state.error = null;
      })
      .addCase(getMessagesForConversation.fulfilled, (state, action) => {
        state.loadingMessages = false;
        state.messages = action.payload.messages; // Store the messages for the current conversation
      })
      .addCase(getMessagesForConversation.rejected, (state, action) => {
        state.loadingMessages = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const {
  setCurrentConversation,
  appendMessageFromUser,
  renameConversation,
  setSelectedModel,
  setCurrentBotReply,
  appendBotMessage,
  cancelBotMessage,
} = messagesSlice.actions;

export default messagesSlice.reducer;
