import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialDark,
  ghcolors,
  vs,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";
import remarkGfm from "remark-gfm"; // Add GFM plugin for table support

function ChatMessage({ message }) {
  // Determine if the message is from the user or the assistant (bot)
  const isUser = message.party === "user";

  // Ensure the message content is a string
  const messageText =
    typeof message.message === "string" ? message.message : "";

  // Custom CodeBlock component to handle code rendering and copy functionality
  const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || "");
    const code = String(children).replace(/\n$/, "");
    const language = match ? match[1] : "Code";
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000); // Display checkmark icon for 3 seconds
    };

    return !inline && match ? (
      <>
        <div className="flex items-center justify-between bg-gray-200 text-slate-600 px-4 py-2 rounded-t-lg mt-4">
          <span className="text-sm font-semibold capitalize">{language}</span>
          <button
            onClick={handleCopyClick}
            className="bg-gray-200 p-1 rounded-md hover:bg-gray-100 focus:outline-none"
            aria-label="Copy code to clipboard"
          >
            {copied ? (
              <CheckIcon className="w-5 h-5 text-slate-600" />
            ) : (
              <ClipboardIcon className="w-5 h-5 text-slate-600" />
            )}
          </button>
        </div>
        <SyntaxHighlighter
          style={vs}
          language={language}
          customStyle={{
            backgroundColor: "#F9F9F9", // Tailwind's blue-800 hex code
            padding: "16px",
            borderRadius: "0 0 8px 8px",
            margin: "0",
            overflowX: "auto",
          }}
          className="w-full mb-4"
          {...props}
        >
          {code}
        </SyntaxHighlighter>
      </>
    ) : (
      <code className={`${className} bg-gray-100 px-1 rounded`} {...props}>
        {children}
      </code>
    );
  };

  return (
    <div
      className={`mb-4 w-full flex ${isUser ? "justify-end" : "justify-start"}`}
    >
      <div
        className={`rounded-lg px-4 py-2 ${
          isUser
            ? "bg-gray-300 text-black self-end max-w-3/4"
            : "bg-white text-gray-800 self-start w-full"
        }`}
      >
        {isUser ? (
          // Plain text for user messages
          <p className="text-black">{messageText}</p>
        ) : (
          // Markdown rendering for bot messages
          <ReactMarkdown
            remarkPlugins={[remarkGfm]} // Enable GFM (tables support)
            components={{
              code: CodeBlock,
              // Table styling
              table({ children, ...props }) {
                return (
                  <div className="overflow-x-auto my-4">
                    <table
                      className="min-w-full table-auto border-collapse border border-gray-300"
                      {...props}
                    >
                      {children}
                    </table>
                  </div>
                );
              },
              thead({ children, ...props }) {
                return (
                  <thead className="bg-gray-100" {...props}>
                    {children}
                  </thead>
                );
              },
              tbody({ children, ...props }) {
                return <tbody {...props}>{children}</tbody>;
              },
              th({ children, ...props }) {
                return (
                  <th
                    className="border border-gray-300 px-4 py-2 text-left font-medium text-gray-900"
                    {...props}
                  >
                    {children}
                  </th>
                );
              },
              td({ children, ...props }) {
                return (
                  <td
                    className="border border-gray-300 px-4 py-2 text-left"
                    {...props}
                  >
                    {children}
                  </td>
                );
              },
              // Headings styling
              h1({ children, ...props }) {
                return (
                  <h1 className="text-2xl font-bold mt-4 mb-2" {...props}>
                    {children}
                  </h1>
                );
              },
              h2({ children, ...props }) {
                return (
                  <h2 className="text-xl font-semibold mt-3 mb-2" {...props}>
                    {children}
                  </h2>
                );
              },
              h3({ children, ...props }) {
                return (
                  <h3 className="text-lg font-semibold mt-3 mb-1" {...props}>
                    {children}
                  </h3>
                );
              },
              // Blockquote styling
              blockquote({ children, ...props }) {
                return (
                  <blockquote
                    className="border-l-4 border-blue-500 pl-4 text-gray-600 italic my-4"
                    {...props}
                  >
                    {children}
                  </blockquote>
                );
              },
              // List styling without custom indentation
              ul({ children, ...props }) {
                return (
                  <ul className="list-disc list-inside ml-4 mb-4" {...props}>
                    {children}
                  </ul>
                );
              },
              ol({ children, ...props }) {
                return (
                  <ol className="list-decimal list-inside ml-4 mb-4" {...props}>
                    {children}
                  </ol>
                );
              },
              li({ children, ...props }) {
                return (
                  <li className="ml-6" {...props}>
                    {children}
                  </li>
                );
              },
              // Paragraph support
              p({ children, ...props }) {
                return (
                  <p className="mb-4" {...props}>
                    {children}
                  </p>
                );
              },
              // Link styling
              a({ children, ...props }) {
                return (
                  <a
                    className="text-blue-500 underline hover:text-blue-600"
                    {...props}
                  >
                    {children}
                  </a>
                );
              },
            }}
          >
            {messageText}
          </ReactMarkdown>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;
