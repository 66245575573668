import React, { useEffect, useState } from "react";
import {
  Bars3Icon,
  PlusIcon,
  EllipsisVerticalIcon,
  TrashIcon,
  LanguageIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewConversation,
  deleteConversation,
  setCurrentConversation,
} from "./store/messageSlice";
import { getToken } from "./authenticator";

function Sidebar({ isOpen, toggleSidebar, provider }) {
  const dispatch = useDispatch();

  const [isAnimating, setIsAnimating] = useState(false);
  const [contentVisible, setContentVisible] = useState(true);

  const displayedText = "JENSEN AI";

  const conversations = useSelector((state) => state.messages.conversations);
  const currentConversation = useSelector(
    (state) => state.messages.currentConversation
  );
  const creatingConversation = useSelector(
    (state) => state.messages.creatingConversation
  );
  const deletingConversation = useSelector(
    (state) => state.messages.deletingConversation
  );

  const addNewChat = async (provider) => {
    const token = await getToken(provider);
    dispatch(createNewConversation(token));
  };

  const deleteConversationFunc = async ({ conversationId, provider }) => {
    const token = await getToken(provider);
    dispatch(deleteConversation({ token, conversationId }));
  };

  const truncateName = (name, maxLength = 20) => {
    return name.length > maxLength
      ? `${name.substring(0, maxLength)}...`
      : name;
  };

  const selectConversation = (conversationId) => {
    if (conversationId !== currentConversation) {
      dispatch(setCurrentConversation(conversationId));
    }
  };

  const handleToggleSidebar = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setContentVisible(false);
    setTimeout(() => {
      toggleSidebar();
    }, 200);
  };

  useEffect(() => {
    if (isAnimating) {
      setTimeout(() => {
        setContentVisible(true);
        setTimeout(() => {
          setIsAnimating(false);
        }, 200);
      }, 300);
    }
  }, [isOpen, isAnimating]);

  const contentOpacityClass = contentVisible ? "opacity-100" : "opacity-0";
  const contentTransitionClass = "transition-opacity duration-200";
  const sidebarVisibilityClass = !isOpen ? "hidden lg:flex" : "";

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 xl:hidden"
          onClick={handleToggleSidebar}
          aria-hidden="true"
        ></div>
      )}

      <div
        onClick={handleToggleSidebar}
        className={`fixed h-screen flex flex-col transition-all duration-300 shadow-md
                ${isOpen ? "w-64" : "w-16"} 
                bg-white border-r border-gray-200 z-50 sm:z-50 ${sidebarVisibilityClass}`}
      >
        <div
          className={`flex items-center ${
            isOpen ? "justify-between" : "justify-center"
          } p-4`}
        >
          {isOpen && (
            <span
              className={`flex items-center text-xl font-semibold text-gray-800 ${contentTransitionClass} ${contentOpacityClass}`}
            >
              {displayedText}
            </span>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleToggleSidebar();
            }}
            className="focus:outline-none"
          >
            <Bars3Icon className="w-6 h-6 text-gray-800" />
          </button>
        </div>

        <div
          className={`flex-1 flex flex-col ${contentTransitionClass} ${contentOpacityClass}`}
        >
          {/* Translator Button */}
          <div
            className={`mt-2 ${isOpen ? "px-2" : ""} flex ${
              isOpen ? "justify-start" : "justify-center"
            }`}
          >
            <a
              href="https://translator.jensen-group.com/simple_term_translation/"
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center ${
                isOpen
                  ? "w-full p-3 justify-start"
                  : "w-10 h-10 p-2 justify-center"
              } rounded-md text-white hover:opacity-90 transition-opacity duration-200`}
              style={{ backgroundColor: "#009BE1" }}
            >
              <LanguageIcon className="w-6 h-6" />
              {isOpen && (
                <span className="ml-3 text-base font-medium">
                  JENSEN Translator
                </span>
              )}
            </a>
          </div>

          {/* New Chat Button */}
          <div
            className={`mt-2 ${isOpen ? "px-2" : ""} flex ${
              isOpen ? "justify-start" : "justify-center"
            }`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addNewChat(provider);
              }}
              className={`flex items-center ${
                isOpen
                  ? "w-full p-3 justify-start"
                  : "w-10 h-10 p-2 justify-center"
              } rounded-md text-gray-700 hover:bg-gray-100 transition-colors duration-200`}
            >
              {creatingConversation || deletingConversation ? (
                <div className="w-6 h-6 border-2 border-t-2 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
              ) : (
                <PlusIcon className="w-6 h-6" />
              )}
              {isOpen && (
                <span className="ml-3 text-base font-medium">New Chat</span>
              )}
            </button>
          </div>

          <nav className={`flex-1 overflow-auto mt-2`}>
            {Array.isArray(conversations) && conversations.length > 0 ? (
              conversations.map((conversation) => (
                <div
                  key={conversation.conversation_id}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectConversation(conversation.conversation_id);
                  }}
                  className={`group flex items-center p-3 mx-2 my-1 rounded-md cursor-pointer z-80 transition-colors duration-200 ${
                    conversation.conversation_id === currentConversation
                      ? "bg-blue-100 text-blue-700 font-semibold"
                      : "text-gray-700 hover:bg-gray-100"
                  } ${isOpen ? "" : "justify-center"}`}
                >
                  <span
                    className={`${isOpen ? "flex-1" : ""} flex items-center`}
                  >
                    {isOpen ? (
                      <span className="truncate" title={conversation.name}>
                        {truncateName(conversation.name)}
                      </span>
                    ) : (
                      <div className="w-6 h-6 bg-gray-300 rounded-full flex items-center justify-center text-sm font-medium text-gray-700">
                        {conversation.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </span>
                  {isOpen && (
                    <div>
                      <Menu>
                        <div>
                          <Menu.Button className="hidden group-hover:block focus:outline-none">
                            <EllipsisVerticalIcon className="w-5 h-5 text-gray-500" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-75"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-60">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteConversationFunc({
                                      conversationId:
                                        conversation.conversation_id,
                                      provider,
                                    });
                                  }}
                                  className={`${
                                    active ? "bg-gray-100" : ""
                                  } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                                >
                                  <TrashIcon className="w-5 h-5 mr-2" />
                                  Delete
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-gray-500 text-center p-4"></div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
