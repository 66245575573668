import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./provider/authProvider";
import store from "../src/store/store";
import { Provider } from "react-redux";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { app } from "@microsoft/teams-js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const initializeTeams = () => {
  app
    .initialize()
    .then(() => {
      console.log("Teams initialized");

      app.notifySuccess();

      root.render(
        <Provider store={store}>
          <App provider={null} />
        </Provider>
      );
    })
    .catch((e) => {
      console.log("Teams not initialized");
      console.log(e);

      root.render(
        <AzureAD provider={authProvider} forceLogin={true}>
          <Provider store={store}>
              <App provider={authProvider} />
          </Provider>
        </AzureAD>
      );

      //return browser;
    });
};
initializeTeams();

// If you want to start measuring performance in your app, pass a functiony
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
