// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_OAUTH_TENANT_ID,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,//"55971fda-e745-43be-b953-6098e68dbba3",
    //postLogoutRedirectUri: window.location.origin,
    //redirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: [
    process.env.REACT_APP_OAUTH_CLIENT_SCOPE,
    //"api://jenbot-test.azurewebsites.net/55971fda-e745-43be-b953-6098e68dbba3/user_impersonation", // Replace 'your-app-id' with your actual App ID
  ],
};

export const authenticationParametersGraph = {
  scopes: ["openid"],
};

// Options
export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
