import React, { useRef, useEffect } from "react";
import ChatMessage from "./ChatMessage";

function ChatMessages({ messages, handleCopy }) {
  const messagesEndRef = useRef(null);

  // Scroll to the bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="flex-1 p-4 flex flex-col w-full overflow-y-auto scrollbar-hide">
      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          index={index} // For alternating user/bot message styles
          handleCopy={handleCopy}
        />
      ))}
      {/* Dummy div to ensure proper scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatMessages;
