import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatMessages from "./components/ChatMessages";
import { getToken } from "./authenticator";
import { getMessagesForConversation } from "./store/messageSlice"; // Import the thunks

// Loading spinner component
const LoadingSpinner = () => (
  <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-75 z-10">
    <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

function MainContent({ provider }) {
  const dispatch = useDispatch();

  // Access messages and current conversation from Redux
  const messages = useSelector((state) => state.messages.messages);
  const currentConversation = useSelector(
    (state) => state.messages.currentConversation
  );
  const loadingMessages = useSelector(
    (state) => state.messages.loadingMessages
  );

  const [inputText, setInputText] = useState("");

  // Fetch messages when currentConversation changes
  useEffect(() => {
    if (currentConversation) {
      getToken(provider).then((token) => {
        const accessToken = token;
        dispatch(
          getMessagesForConversation({
            accessToken,
            conversationId: currentConversation,
          })
        );
      });
    }
  }, [currentConversation, provider, dispatch]);

  // Function to handle code copy
  const handleCopy = (code) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    } else {
      alert("Clipboard API not supported in your browser.");
    }
  };

  return (
    <main className="flex-1 flex flex-col bg-gray-100 items-center py-4">
      <div className="w-full max-w-4xl flex-grow relative">
        {/* Show loading spinner overlay when messages are loading */}

        {/* Chat messages area fills entire parent container */}
        <div className="w-full max-w-4xl px-4">
          <ChatMessages messages={messages} handleCopy={handleCopy} />
        </div>
      </div>
    </main>
  );
}

export default MainContent;
