import React, { useRef, useEffect, useState } from "react";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { appendMessageFromUser } from "../store/messageSlice";

function MessageInput({ handleSubmit, stopStream, provider }) {
  const [inputText, setInputText] = useState("");
  const [hasError, setHasError] = useState(false);
  const textareaRef = useRef(null);
  const dispatch = useDispatch();

  const autoResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResize();
  }, [inputText]);

  const { sendingMessage, currentConversation } = useSelector(
    (state) => state.messages
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (inputText.trim() === "") {
      setHasError(true);
    } else if (!currentConversation) {
      setHasError(true);
      console.error("No conversation selected.");
    } else {
      setHasError(false);

      dispatch(
        appendMessageFromUser({
          PartitionKey: "user added",
          RowKey: "user added",
          message: inputText,
          party: "user",
        })
      );

      handleSubmit(inputText);
      setInputText("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.altKey || e.shiftKey || e.ctrlKey) {
        const cursorPosition = textareaRef.current.selectionStart;
        const newText =
          inputText.slice(0, cursorPosition) +
          "\n" +
          inputText.slice(cursorPosition);
        setInputText(newText);
        e.preventDefault();
      } else {
        e.preventDefault();
        handleFormSubmit(e);
      }
    }
  };

  const handleStopStream = (e) => {
    e.preventDefault();
    stopStream(provider);
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <form onSubmit={handleFormSubmit}>
        <div
          className={`flex items-center ${
            hasError ? "bg-red-100" : "bg-gray-200"
          } shadow-md p-2 rounded-[30px]`}
        >
          <textarea
            ref={textareaRef}
            className="flex-1 resize-none p-2 focus:outline-none bg-transparent placeholder-gray-500 rounded-[30px]"
            rows={1}
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
              setHasError(false);
            }}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
            style={{ maxHeight: "150px", overflowY: "hidden" }}
          />
          <button
            type={sendingMessage ? "button" : "submit"}
            className="ml-2 bg-black text-white rounded-full p-3 focus:outline-none hover:bg-gray-800 transition-colors duration-200 self-end"
            aria-label={sendingMessage ? "Stop Stream" : "Send Message"}
            onClick={sendingMessage ? handleStopStream : undefined}
          >
            {sendingMessage ? (
              <div
                className="w-3 h-3 bg-white m-1"
                style={{ borderRadius: "2px" }}
              ></div>
            ) : (
              <ArrowUpIcon className="w-5 h-5" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default MessageInput;
