import { app, authentication } from "@microsoft/teams-js";

export const getToken = async (provider) => {
  if (app.isInitialized()) {
    try {
      const result = await authentication.getAuthToken({
        claims: null,
        silent: true,
        tenantId: null,
      });
      console.log("Token: " + result.substring(0, 10));
      return result;
    } catch (error) {
      console.error("Error: " + error);
    }
  } else {
    console.log("Browser token");

    const token = await provider.getAccessToken();

    return token.accessToken;
  }
};
