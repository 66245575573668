import React from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  LanguageIcon,
} from "@heroicons/react/20/solid";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedModel } from "./store/messageSlice";

function Header({ toggleSidebar }) {
  const models = useSelector((state) => state.messages.models);
  const selectedModel = useSelector((state) => state.messages.selectedModel);
  const dispatch = useDispatch();

  const setSelectedModelOnClick = (model) => {
    dispatch(setSelectedModel(model));
  };

  return (
    <header className="bg-white shadow-md px-4 sm:px-6 py-1 flex items-center justify-between sticky top-0 z-10">
      {/* Hamburger Menu for small screens */}
      <button
        onClick={toggleSidebar}
        className="lg:hidden focus:outline-none"
        aria-label="Toggle sidebar"
      >
        <Bars3Icon className="w-6 h-6 text-gray-800" />
      </button>

      {/* Dropdown Menu for Model Selection */}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center items-center w-full rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none">
            {(selectedModel && selectedModel.name) || "Select a Model"}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Menu.Items className="origin-top-left absolute mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {models && models.length > 0 ? (
              models.map((model) => (
                <Menu.Item key={model.name}>
                  {({ active }) => (
                    <button
                      onClick={() => setSelectedModelOnClick(model)}
                      className={`${
                        active ? "bg-gray-100" : ""
                      } group flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                    >
                      {model.name}
                    </button>
                  )}
                </Menu.Item>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">
                No models available
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>

      {/* Spacer to push other elements to the right */}
      <div className="flex-grow"></div>

      {/* Translation Button 
      <a
        href="https://ui-translationbackbone-fhhve4bkdkehf8f4.swedencentral-01.azurewebsites.net/term_translation/"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded focus:outline-none mr-4"
        style={{
          backgroundColor: "#009BE1",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#008CD0")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#009BE1")}
      >
        <LanguageIcon className="w-5 h-5 mr-2" aria-hidden="true" />
        JENSEN Translator
      </a>
      */}

      {/* Logo on the right side */}

      <div className="flex items-center">
        <img src="/logo.svg" alt="My Logo" className="h-8 w-auto" />
      </div>
    </header>
  );
}

export default Header;
